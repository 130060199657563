import Header from "../Header";
import TriangleBlur from "../../style/Header/triangleBlur";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import OurTeams from "../OurTeams";
import Bubble from "../Bubble";
import BubbleRecruiter from "../BubbleRecruiter";
import ReferenceCarousel from "../ReferenceCarousel";
import TileButton from "../TileButton";
import { URL } from "../../constants";
import React from "react";

const DepartmentContent = ({ post, headerImage }) => (
  <>
    <Header
      bgImage={headerImage}
      minHeight={["430px", null, "650px", null, null]}
      bottomComponents={<TriangleBlur />}
    >
      <Flex flexDirection="column" justifyContent="flex-end" height="100%">
        <Heading
          as="h1"
          alignSelf="bottom"
          color="white.100"
          mb={3}
          fontSize={[4, null, 5, 6, null]}
          fontWeight="light"
          lineHeight="3"
          maxWidth={["220px", null, "500px", null, null]}
          backgroundPosition="center center"
        >
          {post.name}
        </Heading>
        <Text
          as="p"
          maxWidth="450px"
          color="white.100"
          fontWeight="light"
          mb={80}
          fontSize={2}
        >
          {post.description}
        </Text>
      </Flex>
    </Header>

    <OurTeams title="Naše týmy" teams={post.teams} />

    <Wrapper as="section" bg="EYOffBlack.default">
      <Container
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        mt={["40px", null, null, "80px", null]}
        mb={["20px", "40px", null, "50px", null]}
      >
        <Bubble
          left={true}
          light={false}
          wide={true}
          description={post.yourDay.text}
          name={post.yourDay.profile.name}
          position={post.yourDay.profile.role}
          department={post.yourDay.profile.department}
          image={post.yourDay.profile.image}
          heading={post.yourDay.heading}
          button={
            post.yourDay.link && {
              href: post.yourDay.link,
              label: "Chci vědet víc",
            }
          }
        />
      </Container>
    </Wrapper>

    {post.carousel && <ReferenceCarousel swiperContent={post.carousel} />}

    {post.enjoyYourJob.text && (
      <Wrapper as="section" bg="white.100" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mt={["40px", null, null, "80px", null]}
          mb={["20px", "40px", null, "50px", null]}
        >
          <Bubble
            left={true}
            light={true}
            wide={true}
            description={post.enjoyYourJob.text}
            name={post.enjoyYourJob.profile.name}
            position={post.enjoyYourJob.profile.role}
            image={post.enjoyYourJob.profile.image}
            department={post.enjoyYourJob.profile.department}
            heading={post.enjoyYourJob.heading}
            button={
              post.enjoyYourJob.link && {
                to: post.enjoyYourJob.link,
                label: "Chci vědet víc",
              }
            }
          />
        </Container>
      </Wrapper>
    )}

    <Wrapper as="section" bg="white.100" overflow="hidden">
      <Container
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        mt={["40px", null, null, "80px", null]}
        mb={["20px", "40px", null, "50px", null]}
      >
        <Bubble
          left={false}
          light={true}
          wide={true}
          description={post.projects.text}
          name={post.projects.profile.name}
          position={post.projects.profile.role}
          image={post.projects.profile.image}
          department={post.projects.profile.department}
          heading={post.projects.heading}
          button={
            post.projects.link && {
              to: post.projects.link,
              label: "Chci vědet víc",
            }
          }
        />
      </Container>
    </Wrapper>

    <section>
      <Flex>
        <TileButton
          to={URL.jobs}
          width="100%"
          tileColor="light"
          tileOrientation="right"
        >
          <Flex flexDirection="column" alignItems="flex-end">
            <Text
              fontSize={["18px", null, "5", null, null]}
              color="EYOffBlack.tile"
            >
              Chci se přidat k EY
            </Text>
            <Text
              fontSize={[1, null, 2, null, null]}
              fontWeight="300"
              color="EYOffBlack.tile"
              opacity=".7"
              mt={["8px", null, "24px", null, null]}
            >
              Prohlédni si volné pozice
            </Text>
          </Flex>
        </TileButton>
      </Flex>
    </section>

    {post.experiences && (
      <Wrapper as="section" bg="white.100" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={["20px", "40px", null, "50px", null]}
        >
          <Heading
            as="h2"
            color="EYOffBlack.default"
            fontSize={["4", null, "6", null, null]}
            mt={["60px", null, "120px", null, null]}
            mb={["40px", null, "60px", null, null]}
            fontWeight="light"
            textAlign="center"
          >
            Vyšleme tě do světa na zkušenou
          </Heading>

          <Bubble
            left={false}
            light={true}
            wide={true}
            description={post.experiences.text}
            name={post.experiences.profile.name}
            position={post.experiences.profile.role}
            department={post.experiences.profile.department}
            image={post.experiences.profile.image}
            button={
              post.experiences.link && {
                to: post.experiences.link,
                label: "Chci vědet víc",
              }
            }
          />
        </Container>
      </Wrapper>
    )}
    {post.recruiter && (
      <Wrapper as="section" bg="EYOffBlack.default" overflow="hidden">
        <Container
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mt={["60px", null, "120px", null, null]}
          mb={["20px", "40px", null, "50px", null]}
        >
          <BubbleRecruiter
            left={true}
            light={false}
            wide={true}
            title="Zajímá tě ještě něco dalšího?"
            recruiter={post.recruiter}
          />
        </Container>
      </Wrapper>
    )}
  </>
);

export default DepartmentContent;
