import React from "react";

import { Wrapper } from "../style/Grid";
import RecruiterPhoneNumber from "./RecruiterPhoneNumber";
import { recruiterPhoto } from "../utils/contentPhoto";
import Bubble from "./Bubble";
import { BubbleRecruiterStyle } from "../style/Bubble/BubbleRecruiter";
import getStyledLink from "../style/Link/getStyledLink";
const Link = getStyledLink("a");

const BubbleRecruiter = (props) => {
  if (!props.recruiter || !props.recruiter.frontmatter) return <></>;
  return (
    <Bubble
      left={true}
      light={false}
      wide={true}
      name={props.recruiter.frontmatter.name}
      position={props.recruiter.frontmatter.role}
      image={recruiterPhoto(props.recruiter.frontmatter.name)}
      renderRawDescription={true}
      description={
        <Wrapper as="span" display="inline">
          <h3>
            <Wrapper
              as="span"
              display="inline-block"
              color="#ffe600"
              fontWeight="300"
              mt="1.5em"
            >
              {props.title}
            </Wrapper>
          </h3>
          <BubbleRecruiterStyle>
            <p color="white" style={{ marginBottom: "10px" }}>
              <RecruiterPhoneNumber
                phoneNumber={props.recruiter.frontmatter.phone}
                white={!props.light}
                textDecoration="none"
              />
              <Link
                href={`mailto:${props.recruiter.frontmatter.email}`}
                white={!props.light}
                textDecoration="none"
              >
                {props.recruiter.frontmatter.email}
              </Link>
            </p>
          </BubbleRecruiterStyle>
        </Wrapper>
      }
    />
  );
};

export default BubbleRecruiter;
