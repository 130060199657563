import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const BubbleRecruiterStyle = styled.span`
  font-weight: ${themeGet("fontWeights.light")};

  span + a {
    display: inline-block;
    margin-top: 6px;
  }
`;

BubbleRecruiterStyle.defaultProps = {};

export { BubbleRecruiterStyle };
