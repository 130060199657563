import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import propTypes from "@styled-system/prop-types";

const ToggleButton = styled.button`
  border: 0;
  outline: 0;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      .mainButton {
        transform: rotate(90deg);
      }
    }
  }
`;

ToggleButton.defaultProps = {
  fontSize: 3,
  fontWeight: "light",
  textAlign: "left",
  backgroundColor: "transparent",
  color: "white.100",
};

ToggleButton.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { ToggleButton };
