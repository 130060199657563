import styled from "styled-components";
import { space, layout } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

const SwiperCustomProgressWrapper = styled.div`
  position: relative;
  ${layout}
  ${space}
  background-color: ${themeGet("colors.white.50")};
  @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
    margin-left: -4.5%;
    margin-right: -4.5%;
  }
`;
SwiperCustomProgressWrapper.displayName = `SwiperCustomProgressWrapper`;

const SwiperCustomProgress = styled.div`
  position: relative;
  ${layout}
  background-color: ${themeGet("colors.EYYellow.default")};
  width: 0;

  &.a0 {
    width: 0;
    transition: none;
  }

  &.a100 {
    width: 100%;
    transition: width ${(props) => props.delay}ms linear;
  }
`;
SwiperCustomProgress.displayName = `SwiperCustomProgress`;

SwiperCustomProgress.defaultProps = {
  height: ["3px", null, null, null, null],
};

const ReferenceCarouselWrapper = styled.div`
  ${layout}
  margin: 0 auto;
  .swiper-pagination {
    bottom: auto !important;
    display: block;
    top: 80px;
  }
  .image {
    display: block;
    width: auto;
    height: 51px;
    margin: 0 auto 80px auto;

    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      ${(props) => `margin: 0 auto ${props.imageMarginBottom}px auto;`}
    }
  }

  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    position: relative;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 0 22px 0 0;
    margin: 0 0 0 17px;
    text-transform: uppercase;
    font-size: ${themeGet("fontSizes.2")};
    font-weight: ${themeGet("fontWeights.light")};
    background-color: transparent;
    color: ${themeGet("colors.white.50")};

    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      margin: 0 0 0 17px;
    }

    &:focus {
      outline: none;
    }
    &:after {
      content: "|";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-weight: ${themeGet("fontWeights.light")};
      color: ${themeGet("colors.EYYellow.default")};
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &.swiper-pagination-bullet-active {
      width: auto;
      height: auto;
      color: ${themeGet("colors.white.100")};
    }
  }
  .swiper-wrapper {
    margin-bottom: 60px;
  }
`;

ReferenceCarouselWrapper.defaultProps = {
  width: [null, null, null, null, "630px"],
};

export {
  SwiperCustomProgress,
  ReferenceCarouselWrapper,
  SwiperCustomProgressWrapper,
};
