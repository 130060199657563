import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import DepartmentContent from "../components/DepartmentContent";
import usePageView from "../utils/usePageView";

const DepartmentPostTemplate = (props) => {
  usePageView(props.location.pathname, "O EY", "Team");

  const {
    data: {
      mdx: { frontmatter: post },
    },
  } = props;

  const getHeaderImage = () => {
    switch (post.name) {
      case "Strategie a transakce":
        return props.data.departmentPhoto1;
      case "Audit":
        return props.data.departmentPhoto2;
      case "Business Consulting":
        return props.data.departmentPhoto3;
      case "Daně":
        return props.data.departmentPhoto4;
      case "Forenzní služby":
        return props.data.departmentPhoto5;
      case "Technology Consulting":
        return props.data.departmentPhoto6;
      case "Právo":
        return props.data.departmentPhoto7;
      default:
        return null;
    }
  };

  return (
    <Layout location={props.location} title="event">
      <SEO title={post.name} description={post.description || post.excerpt} />
      <DepartmentContent
        post={post}
        recruiterBubbleContent={""}
        headerImage={getHeaderImage()}
      />
    </Layout>
  );
};

export default DepartmentPostTemplate;

export const pageQuery = graphql`
  query DepartmentPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        carousel {
          name
          logo
          title
        }
        teams {
          name
          description
        }
        description
        name
        yourDay {
          heading
          text
          link
          profile {
            ...profile
          }
        }
        experiences {
          text
          profile {
            ...profile
          }
        }
        enjoyYourJob {
          heading
          text
          link
          profile {
            ...profile
          }
        }
        projects {
          heading
          text
          profile {
            ...profile
          }
        }
        recruiter {
          frontmatter {
            name
            phone
            email
            role
          }
        }
      }
    }
    departmentPhoto1: file(relativePath: { eq: "departments/transakce.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto2: file(relativePath: { eq: "departments/audit.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto3: file(relativePath: { eq: "departments/advisory.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto4: file(relativePath: { eq: "departments/dane.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto5: file(
      relativePath: { eq: "departments/forenzni_sluzby.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto6: file(
      relativePath: { eq: "departments/it_consulting.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
    departmentPhoto7: file(relativePath: { eq: "departments/pravo.png" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 2560, quality: 90)
      }
    }
  }

  fragment profile on Profile {
    name
    role
    department
    image {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
  }
`;
