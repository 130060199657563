import React, { useEffect, useState, useRef } from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

import {
  SwiperCustomProgress,
  ReferenceCarouselWrapper,
  SwiperCustomProgressWrapper,
} from "../../style/ReferenceCarousel";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import nbsp from "../../utils/nbsp";

const ReferenceCarousel = ({ swiperContent }) => {
  const delay = 15000;
  let autoplay = null;

  const [activeIndex, setActiveIndex] = useState(0);

  const ref = useRef(null);

  const handlePaginationItemClick = (index) => {
    if (ref.current !== null && ref.current.swiper !== null) {
      setActiveIndex(index);
      ref.current.swiper.slideTo(index);
    }
  };

  const handleAfterSlideEnd = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      setActiveIndex(ref.current.swiper.realIndex);
    }
  };

  const setAutoplay = () => {
    autoplay = setInterval(() => {
      if (ref.current !== null && ref.current.swiper !== null) {
        if (ref.current.swiper.realIndex + 1 === swiperContent.length) {
          setActiveIndex(0);
          ref.current.swiper.slideTo(0);
        } else {
          setActiveIndex(ref.current.swiper.realIndex + 1);
          ref.current.swiper.slideTo(ref.current.swiper.realIndex + 1);
        }
      }
    }, delay);
  };

  const resetAutoplay = () => {
    removeInterval();
    setAutoplay();
  };

  const removeInterval = () => {
    clearInterval(autoplay);
  };

  const swiperParams = {
    autoplay: {
      delay: delay,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (className, index) => {
        return (
          <span
            className={className}
            onClick={() => handlePaginationItemClick(index)}
          >
            {swiperContent[index].name}
          </span>
        );
      },
    },
    renderPagination: (props) => {
      return (
        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
          {props.children.map((item, index) =>
            props.pagination.renderBullet(
              `swiper-pagination-bullet ${
                activeIndex == index && "swiper-pagination-bullet-active"
              }`,
              index
            )
          )}
        </div>
      );
    },
    on: {
      init: () => {
        setAutoplay();
        document.getElementById("SwiperCustomProgress") &&
          document
            .getElementById("SwiperCustomProgress")
            .classList.remove("a0");
        document.getElementById("SwiperCustomProgress") &&
          document.getElementById("SwiperCustomProgress").classList.add("a100");
      },
      transitionStart: () => {
        document.getElementById("SwiperCustomProgress") &&
          document
            .querySelector("#SwiperCustomProgress")
            .classList.remove("a100");
        document.getElementById("SwiperCustomProgress") &&
          document.querySelector("#SwiperCustomProgress").classList.add("a0");
      },
      transitionEnd: () => {
        resetAutoplay();
        handleAfterSlideEnd();
        document.getElementById("SwiperCustomProgress") &&
          document
            .querySelector("#SwiperCustomProgress")
            .classList.remove("a0");
        document.getElementById("SwiperCustomProgress") &&
          document.querySelector("#SwiperCustomProgress").classList.add("a100");
      },
      click: () => {
        document.getElementById("SwiperCustomProgress") &&
          document
            .querySelector("#SwiperCustomProgress")
            .classList.remove("a0");
        document.getElementById("SwiperCustomProgress") &&
          document.querySelector("#SwiperCustomProgress").classList.add("a100");
      },
      destroy: () => {
        removeInterval();
      },
    },
  };

  const [heightOfNav, setHeightOfNav] = useState(30);

  const setHeight = () =>
    document.querySelector(".swiper-pagination") &&
    setHeightOfNav(document.querySelector(".swiper-pagination").offsetHeight);

  useEffect(() => {
    window.addEventListener("resize", setHeight);
    setHeight();
    return () => window.removeEventListener("resize", setHeight);
  });

  return (
    <Wrapper as="div" bg="EYOffBlack.default" id="spoluprace">
      <Container flexDirection="column">
        <ReferenceCarouselWrapper
          maxWidth="630px"
          width="100%"
          imageMarginBottom={heightOfNav + 30}
        >
          <Swiper ref={ref} {...swiperParams}>
            {swiperContent.map((item, index) => (
              <div key={index}>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems={["flex-start", "center", null, null]}
                  mt="12px"
                >
                  {item?.logo?.childImageSharp ? (
                    <Image
                      image={item.logo}
                      height={["95px", null, null, null, null]}
                      alt={item.name}
                      mx="auto"
                    />
                  ) : (
                    <Image
                      src={item.logo}
                      height={["95px", null, null, null, null]}
                      alt={item.name}
                    />
                  )}

                  <Text
                    color="white.100"
                    mb="4"
                    textAlign={["left", "center", null, null]}
                    fontSize={["24px", null, null, 5, null]}
                    fontWeight="light"
                    lineHeight="3"
                    backgroundPosition="center center"
                    width="100%"
                  >
                    {nbsp(item.title)}
                  </Text>
                </Flex>
              </div>
            ))}
          </Swiper>
        </ReferenceCarouselWrapper>

        <SwiperCustomProgressWrapper
          mb={5}
          ml={["-4.5%", "0", null, null]}
          mr={["-4.5%", "0", null, null]}
        >
          <SwiperCustomProgress id="SwiperCustomProgress" delay={delay} />
        </SwiperCustomProgressWrapper>
      </Container>
    </Wrapper>
  );
};

export default ReferenceCarousel;
