import React, { useState } from "react";
import { chunk, ceil } from "lodash";
import { Heading } from "../../style/Heading";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";
import { ToggleButton } from "../../style/OurTeams";
import Icon from "../Icons";
import styled from 'styled-components';
import { theme } from "../../style/theme";

const MainButton = styled.span`
  width: 19px;
  height: 19px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  position: relative;
  transition: transform ease-in-out 300ms;

  ${({ active }) =>
  active &&
  `
        transform: rotate(180deg) !important;

        & > *:nth-child(2) {
            opacity: 0;
        }
  `}
`;

const Line = styled.div`
  background-color: ${theme.colors.EYYellow.default};
  position: absolute;
  transition: opacity ease-in-out 300ms;

  &:nth-child(1) {
    width: 100%;
    height: 1px;
    left: 0;
    top: 9px;
  }

  &:nth-child(2) {
    width: 1px;
    height: 100%;
    left: 9px;
    top: 0;
  }
`;

const Toggler = ({ name, description, last }) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <Flex
      flexDirection="column"
      alignItems="middle"
      borderTop={["borderToggle", null, null, null]}
    >
      {description ? (
        <ToggleButton
          px={0}
          py={3}
          lineHeight={1}
          onClick={() => setVisible(!isVisible)}
        >
          <Flex justifyContent="space-between" alignItems="center">
            {name}
            <Flex width="16px" height="16px" color="#FFE600" ml="16px" display="grid">
              <MainButton active={isVisible} className="mainButton">
                <Line />
                <Line />
              </MainButton>
              {/*<Icon name={isVisible ? "minus" : "plus"} />*/}
            </Flex>
          </Flex>
        </ToggleButton>
      ) : (
        <ToggleButton px={0} py={3} lineHeight={1} style={{ cursor: "auto" }}>
          {name}
        </ToggleButton>
      )}

      {isVisible && description && (
        <Box mb={3}>
          <Text
            lineHeight="3"
            color="white.100"
            fontWeight="light"
            fontSize={2}
          >
            {description}
          </Text>
        </Box>
      )}
    </Flex>
  );
};

const OurTeams = ({ title, teams }) => {
  const splitTeams =
    teams.length === 2 ? teams : chunk(teams, ceil(teams.length / 2));

  return (
    <Wrapper as="div" bg="EYOffBlack.default">
      <Container flexDirection="column">
        <Heading
          as="h3"
          color="EYYellow.default"
          mb={[3, 4, null, null, 5]}
          fontSize={[4, null, null, 5, null]}
          fontWeight="light"
          lineHeight={3}
          maxWidth={["170px", null, "500px", null, null]}
          backgroundPosition="center center"
        >
          {title}
        </Heading>

        <Flex
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
          mb={5}
        >
          {teams.length === 2 && (
            <Flex
              width={["100%", null, "calc(50% - 12.5px)"]}
              flexDirection="column"
            >
              {splitTeams &&
                splitTeams.map((team, index) => (
                  <Toggler
                    key={index}
                    {...team}
                    last={index === splitTeams.length - 1}
                  />
                ))}
            </Flex>
          )}
          {teams.length !== 2 && (
            <>
              <Flex
                width={["100%", null, "calc(50% - 12.5px)"]}
                flexDirection="column"
              >
                {splitTeams[0] &&
                  splitTeams[0].map((team, index) => (
                    <Toggler
                      key={index}
                      {...team}
                      last={index === splitTeams[0].length - 1}
                    />
                  ))}
              </Flex>
              <Flex
                width={["100%", null, "calc(50% - 12.5px)"]}
                flexDirection="column"
              >
                {splitTeams[1] &&
                  splitTeams[1].map((team, index) => (
                    <Toggler
                      key={index}
                      {...team}
                      aaa={index}
                      last={index === splitTeams[1].length - 1}
                    />
                  ))}
              </Flex>
            </>
          )}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default OurTeams;
